import 'bootstrap/dist/css/bootstrap.css';
import "./style.scss";
import {useEffect, useState} from "react";
import callAPI from "../../api";
import {useSearchParams} from "react-router-dom";
import DownloadGeneric from "../../templates/DownloadGeneric";
import DownloadGenericTwo from "../../templates/DownloadGenericTwo";
import {useSystem} from '../../hooks/use-system';
import DownloadGenericVideo from '../../templates/DownloadGenericVideo';
import CheckoutOne from "../../templates/CheckoutOne";
import bosnia from "../../config/ba.js";
import bosniaHQ from "../../config/ba-hq.js";
import bosniaEtheria from "../../config/ba-eth.js";
import meEtheria from "../../config/me-eth.js";
import roEtheria from "../../config/ro_etheria.js";
import frEtheria from "../../config/fr_etheria.js";
import montenegro from "../../config/me.js";
import luxembourg from "../../config/lu.js";
import chMobilePlay from "../../config/ch-mobileplay";
import chMindAcademy from "../../config/ch-mindacademy";
import serbia from "../../config/rs.js";
import kw from '../../config/kw.js'
import nordBg from '../../config/nordBg.js'
import nordSi from '../../config/nordSI.js'

import featured_image from '../../assets/images/braingym.png'
import { Helmet } from 'react-helmet';


function Home() {

    const {config, language, setConfig, setLanguage, webClickId, setWebClickId, loading, setLoading} = useSystem();
    const [configCalled, setConfigCalled] = useState(false);
    const [errorMessage, setError] = useState(null);
    const [searchParams] = useSearchParams();


    const configDict = {
        // 'ba.hqbraingamesonline.com': bosniaHQ,
        // 'ba.thebraingyms.com': bosnia,
        'signups-ch.thebraingyms.com': chMindAcademy,
        'signups-ba.brain-gym.mobi': bosnia,
        's-ba.getyourdownload.online': bosniaHQ,
        'me.thebraingyms.com': montenegro,
        'me.braingym.store': montenegro,
        'signups-me.brain-gym.mobi': montenegro,
        'lu.mindacademy.life': luxembourg,
        // 'signups-ba.etheria.games': bosniaEtheria,
        // 'signups-me.etheria.games': meEtheria,
        // 'signups-ro.etheria.games': roEtheria,
        // 'signups-fr.etheria.games': frEtheria,
        // 'flxtr-kw.etheria.games': kw,
        // 'signups-ch.mobilespacegames.com': chMobilePlay,
        // 'nord-bg.etheria.games': nordBg,
        // 'nord-si.etheria.games': nordSi,
        // '192.168.1.101': nordBg,
        // 'localhost': chMindAcademy,
    }

    useEffect(() => {
        // setLoading(true);
        if (!configCalled) {
            setConfigCalled(true);

            callAPI({
                path: window.location.pathname
            }, 'getPageConfig').then((data) => {
                    if (data.status === 'success') {
                        setLoading(false);
                        const conf = data.payload.page_config;

                        const detectedLang = detectLang(conf.supported_languages)
                        setLanguage(detectedLang);
                        setConfig(conf);
                        saveTraffic(conf.public_id, conf.flow);

                    }else{
                        setError('Invalid Page')
                    }
                }
            );
            // const conf = configDict[window.location.hostname];
            /*if(conf){
                if(window.location.hostname === 'me.braingym.store'){
                    console.log(conf['featured_image'])
                    conf['featured_image'] = featured_image;
                }
                const detectedLang = detectLang(conf.supported_languages)
                if (Array.isArray(conf.texts)) {
                    const texts = conf.texts
                    conf.texts = {}
                    conf.texts[detectedLang] = texts
                }
                setLanguage(detectedLang)
                setConfig(conf);

                saveTraffic(conf.public_id, conf.flow);
            } else {
                setError('Invalid Page')
            } */

        }
    }, [])

    const getBrowserLang = () => {
        const browserLanguage = navigator.language || navigator.userLanguage;
        return browserLanguage.slice(0, 2);
    }
    const detectLang = (supported_languages) => {
        const supportedLangs = Object.keys(supported_languages)
        let detectedLang = supportedLangs
        const savedLanguage = localStorage.getItem('nj_lang');
        if (savedLanguage) {
            detectedLang = savedLanguage;
        } else {
            const browserLanguage = getBrowserLang()
            if (supportedLangs.includes(browserLanguage)) {
                detectedLang = browserLanguage
            } else {
                detectedLang = supportedLangs[0]
            }
        }
        return detectedLang
    }

    const saveTraffic = (configId, flow) => {
        let clickId = searchParams.get('gclid');
        if (clickId === null) {
            clickId = searchParams.get('wbraid');
            if (clickId === null) {
                clickId = searchParams.get('gbraid');
                if (clickId === null) {
                    clickId = searchParams.get('click_id')
                }
            }
        }

        // if (clickId) {
            // setLoading(true);
            callAPI({
                click_id: clickId,
                config_id: configId,
                path: window.location.pathname,
                qs: window.location.search,
            }, 'saveTraffic').then((data) => {
                if (data.status === 'success') {
                    localStorage.setItem('nj_clickId', data.payload.ref_id);
                    setWebClickId(data.payload.ref_id);

                    setConfig((prevConfig)=>({
                                        ...prevConfig,
                                        short_code: data.payload.short_code,
                                        keyword: data.payload.keyword,
                                        antifraud_js: data.payload.antifraud_js,
                                        antifraud_token: data.payload.anti_fraud_token,
                                    }))
                    if (data.payload.sms_suffix) {
                        localStorage.setItem('nj_smsSuffix', data.payload.sms_suffix);
                    }
                    if (flow === 'INIT_MSISDN_REDIRECT' || flow === 'MSISDN_REDIRECT') {
                        localStorage.setItem('nj_sessionId', data.payload.session_id)
                        localStorage.setItem('nj_token', data.payload.token)
                    }
                }
                // setLoading(false);
            })
        // }
    }

    useEffect(() => {
        try {
            if (config) {
                localStorage.setItem('nj_configId', config.public_id)
                if (config.country_name) {
                    const domainElms = window.location.host.split(".");
                    const domain = window.location.host.replace(domainElms.length > 2 ? domainElms[0] + "." : "", "");
                    const title = domain[0].toUpperCase() + domain.substring(1) + ' | ' + config.country_name;
                    document.title = title;
                }
            }
        } catch (e) {

        }

    }, [config])

    const getTemplate = (config) => {
        let template = <DownloadGeneric/>
        if (config.template === 'generic-download-2') {
            template = <DownloadGenericTwo/>
        } else if (config.template === 'generic-download-video') {
            template = <DownloadGenericVideo/>
        } else if (config.template === 'checkout-1') {
            template = <CheckoutOne/>
        }
        return template;
    }

    return (
        config && language && !loading ? (
            <>
            {config.antifraud_js &&
                <Helmet>
                    <script type='text/javascript'>{config.antifraud_js}</script>
                </Helmet>
            }
            { getTemplate(config) }
            </>
        ) : (
            <div id={"loader"} className={"d-flex align-items-center vw-100 justify-content-center vh-100"}>
                <div className={'my-auto flex-column d-flex'}>

                    <h5>{errorMessage}</h5>

                    {!errorMessage && <>
                        <div className="pageLoader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        {/*<div className="spinner-border spinner-border-sm text-dark"><span*/}
                        {/*    className="visually-hidden"> Loading...</span>*/}
                        {/*</div>*/}
                        {/*&nbsp;loading...*/}
                    </>}
                </div>
            </div>
        )
    )
}

export default Home;
